<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          ><i class="el-icon-lx-copy"></i> 个人中心</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="bar">
        <div class="barName">头像：</div>
        <el-image class="barImg" :src="userData.image"></el-image>
        <!-- <el-button class="right">修改密码</el-button> -->
      </div>
      <div class="bar">
        <div class="barName">名字：</div>
        <div class="barCentent">{{userData.name}}</div>
      </div>
      <div class="bar">
        <div class="barName">手机号：</div>
        <div class="barCentent">{{userData.phone}}</div>
      </div>
      <div class="bar">
        <div class="barName">医护类型：</div>
        <div class="barCentent">{{userData.type}}</div>
      </div>
      <div class="bar">
        <div class="barName">登录账号：</div>
        <div class="barCentent">{{userData.username}}</div>
      </div>

      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="密码：" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { userInfo,setPassword } from "../api/index";
export default {
  name: "userInfo",
  data() {
     var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      userData: {},
      ruleForm: {
          pass: '',
          checkPass: ''
      },
      rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ],
      }
    };
  },
  created() {
    // this.$store.commit("clearTags");
    userInfo().then((res)=>{
      this.userData = res
    })
  },
  methods: {
     submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let password = this.ruleForm.checkPass
            setPassword({password}).then(()=>{
              this.$message.success("修改成功");
            })
          }
        });
      }
  },
  computed: {
   
  },
};
</script>

<style>
.bar {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  font-size: 14px;
  color: #606266;
}
.barImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.barName {
  width: 100px;
  text-align: right;
}
.right {
  position: absolute;
  right: 0;
  top: 30px;
}
.barInp {
  width: 400px;
}
.btn {
  width: 150px;
  margin-left: 100px;
}
.demo-ruleForm{
  width: 400px;
}
</style>

